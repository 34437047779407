import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
new Splide('.carouselFeedback__splide', {
    lazyLoad: 'nearby',
    focus: 0,
    omitEnd: true,
    perPage: 3,
    perMove: 3,
    gap: '4%',
    pagination: false,
    breakpoints: {
        950: { perPage: 2, perMove: 2 },
        700: { perPage: 1, perMove: 1 }
    }
}).mount();
document.addEventListener('DOMContentLoaded', () => {
    // Массив всех карточек
    const cards = Array.from(document.querySelectorAll('.carouselFeedback__splide .feedbackCard'));
    // Массив кнопок карусели
    const splideArrowButtons = Array.from(document.querySelectorAll('.carouselFeedback .arrowButton'));
    // Список открытых карточек с колбэками закрытия
    const openedCards = {};
    // Закрытие всех открытых карточек
    const closeAllCards = () => {
        Object.values(openedCards).forEach(closeCard => closeCard());
    };
    // Настройка карточек
    cards.forEach((card) => {
        // <p /> с текстом комментария
        const contentParagraph = card.querySelector('.feedbackCard__content');
        // Действительная высота параграфа
        const paragraphActualHeight = contentParagraph.scrollHeight;
        // Отрисованная высота параграфа
        const paragraphRenderedHeight = contentParagraph.clientHeight;
        const showMoreButton = card.querySelector('.showMoreButton');
        let isCardOpened = false;
        // Выводим кнопку "Показать полность",
        // если размер текста больше доступной высоты
        if (paragraphActualHeight > paragraphRenderedHeight) {
            showMoreButton.classList.replace('hidden', 'block');
        }
        // Открытие карточки
        const openCard = () => {
            card.classList.replace('static', 'absolute');
            // Анимация появления тени
            if (card.classList.contains('animate-shadow-out')) {
                card.classList.replace('animate-shadow-out', 'animate-shadow-in');
            }
            else {
                card.classList.add('animate-shadow-in');
            }
            showMoreButton.classList.add('showMoreButton_opened');
            contentParagraph.classList.remove('max-h-[144px]', 'line-clamp-6');
            contentParagraph.style.maxHeight = `${paragraphActualHeight}px`;
            // Запись информации об открытой карточке
            openedCards[card.id] = closeCard;
            isCardOpened = true;
        };
        // Закрытие карточки
        const closeCard = () => {
            card.classList.replace('animate-shadow-in', 'animate-shadow-out');
            showMoreButton.classList.remove('showMoreButton_opened');
            contentParagraph.classList.add('max-h-[144px]');
            contentParagraph.style.maxHeight = '';
            // Параметры, которые должны быть изменены по окончании анимации
            setTimeout(() => {
                card.classList.replace('absolute', 'static');
                contentParagraph.classList.add('line-clamp-6');
            }, 300);
            // Удаление информации об открытой карточке
            delete openedCards[card.id];
            isCardOpened = false;
        };
        // Переключение карточки
        const toggleCard = () => {
            if (isCardOpened) {
                closeCard();
            }
            else {
                openCard();
            }
        };
        showMoreButton.addEventListener('click', () => {
            toggleCard();
        });
    });
    // Настройка кнопок карусели
    splideArrowButtons.forEach(button => button.addEventListener('click', closeAllCards));
});
